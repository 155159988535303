<template>
    
    <div>
        <div class="titleDelectDiv">
            <span class="title">{{bigTitle}}</span>
            <div class="delect" @click="toDelect">删除</div>
        </div>
        
        <div class="uploaderDiv">
            <div class="smalltitleDiv">
                <van-field  :name="getForID" v-model="id" style="display:none"/>
                <p class="smallTitle">{{smellTitle}}</p>
               
                <div  class="selectTime">
                  <van-field
                    readonly
                    clickable
                    :name="timeName"
                    :value="timeLabel"
                    placeholder="请选择时间"
                    @click="showPopup"
                  />
                </div>
               
                <van-popup v-model="show">
                    <van-datetime-picker
                        v-model="currentDate"
                        type="year-month"
                        title="请选择年月"
                        :min-date="minDate"
                        :max-date="maxDate"
                        @cancel="timeCancel"
                        @confirm="timeConfirm"
                        :formatter="formatter"
                    />
                </van-popup>
            </div>
            <div  v-if="isPower">
                <div class="smallLineDiv"> 
                    <van-field label="用电费用(元)" placeholder="请输入" :name="totalCost" v-model="ttotalCost"/>
                </div>
            </div>
            <div  v-if="isWater">
                <div class="smallLineDiv">
                    <van-field label="尖峰度数" placeholder="请输入" v-model="ttipPeakdegree" :name="tipPeakdegree"/>
                </div>
                <div class="smallLineDiv">
                    <van-field label="高峰度数" placeholder="请输入" v-model="hhighPeakdegree" :name="highPeakdegree"/>
                </div>
                <div class="smallLineDiv">
                    <van-field label="平谷度数" placeholder="请输入" v-model="fflatValley" :name="flatValley"/>
                </div>
                <div class="smallLineDiv">
                    <van-field label="低谷度数" placeholder="请输入" v-model="ttroughDegree"  :name="troughDegree"/>
                </div>
            </div>
            
       
            <van-field :name="imageList" >
              <template #input>
                    <van-uploader v-model="fileList" max-count="1" class="touploader" :after-read="afterRead" :before-delete="deleteImg">
                      <img :src="require('../../assets/image/loder/'+uploaderImage)" alt="" >
                     </van-uploader>
              </template>
            </van-field>
           
            

        </div>
    </div>

</template>
<script>
 import Exif from 'exif-js'
import {uploads} from '../../network/uploder'

//1电费，2，核稽单，3水费表

export default{
    name:'UploaderImage',
    data(){
        return{
          fileList:this.item.uploadInvoice ? [ {url:this.item.uploadInvoice}] : [],
            files:{},
            minDate: new Date(2015, 0),
            maxDate: new Date(),
            currentDate: new Date(),
            show: false,
            timeLabel:this.item ? this.item.invoiceTime : '',

            ttotalCost:this.item ?this.item.totalCost : '',//用电费用
            ttipPeakdegree:this.item ?this.item.tipPeakdegree:'',
            hhighPeakdegree:this.item ?this.item.highPeakdegree:'',
            fflatValley:this.item ?this.item.flatValley:'',
            ttroughDegree:this.item ?this.item.troughDegree:'',
            id:this.item ?this.item.id:'',
        }
    },
    props:{
        bigTitle:{
            type:String,
            default:'表单'
        },
        smellTitle:{
            type:String,
            default:'表单一'
        },
        maxCount:{
            type:Number,
            default:1
        },
        uploaderImage:{
            type:String,
            default:'checkImge.png'
        },
        isnum:{
          type:Number,
          
        },
        isPower:{
            type:Boolean,
            default:false
        },
        isWater:{
            type:Boolean,
            default:false
        },
        item:{
            type:Object,
        }
    },
    computed:{
      timeName(){
        let index = this.isnum - 1;
        let timeLoderKey;
        if(this.isPower == true){
            timeLoderKey = 'assessmentEnclosure1['+ index +'].invoiceTime';
        }else if(this.isWater == true){
            timeLoderKey = 'assessmentEnclosure3['+ index +'].invoiceTime';
        }else {
            timeLoderKey = 'assessmentEnclosure2['+ index +'].invoiceTime';
        }
         return  timeLoderKey;
      
      },
      imageList(){
            let index = this.isnum - 1;
            let photoLoderKey;
            if(this.isPower == true){
                photoLoderKey = 'assessmentEnclosure1['+ index +'].uploadInvoice';
            }else if(this.isWater == true){
                photoLoderKey = 'assessmentEnclosure3['+ index +'].uploadInvoice';
            }else {
                photoLoderKey = 'assessmentEnclosure2['+ index +'].uploadInvoice';
            }
            return  photoLoderKey;
      },
      totalCost(){
        let index = this.isnum - 1;
        return 'assessmentEnclosure1['+ index +'].totalCost';
      },
      tipPeakdegree(){
        let index = this.isnum - 1;
        return 'assessmentEnclosure3['+ index +'].tipPeakdegree';
      },
      highPeakdegree(){
        let index = this.isnum - 1;
        return 'assessmentEnclosure3['+ index +'].highPeakdegree';
      },
      flatValley(){
        let index = this.isnum - 1;
        return 'assessmentEnclosure3['+ index +'].flatValley';
      },
      troughDegree(){
        let index = this.isnum - 1;
        return 'assessmentEnclosure3['+ index +'].troughDegree';
      },
      getForID(){
        if(this.item){
            let index = this.isnum - 1;
            let photoLoderKey;
            if(this.isPower == true){
                photoLoderKey = 'assessmentEnclosure1['+ index +'].id';
            }else if(this.isWater == true){
                photoLoderKey = 'assessmentEnclosure3['+ index +'].id';
            }else {
                photoLoderKey = 'assessmentEnclosure2['+ index +'].id';
            }
            return  photoLoderKey;
        }else{
            return null;
        }
            
      }



    },
    methods:{
        toDelect(){
            this.$emit('deleteIndex',this.isnum)
        },
        showPopup() {
            this.show = true;
        },
        timeCancel() {
            //时间取消
             this.show = false;
        },
        timeConfirm(value) {
            //时间确定
            let time =
                new Date(value).getFullYear() +
                "/" +
                (new Date(value).getMonth() + 1) ;
            this.timeLabel = time;
            this.show = false;
           
        },
        formatter(type, val) {
            if (type === "year") {
                return `${val}年`;
            } else if (type === "month") {
                return `${val}月`;
            }
            return val;
        },
        deleteImg(){
           this.fileList.shift();
        },

       // 上传图片
       afterRead(file) {
          this.files.name = file.file.name;
          this.files.type = file.file.type;
          this.imgPreview(file.file)
      },
       // 处理图片
       imgPreview(file) {
                    let self = this
                    let Orientation
                    //去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save   这里需要安装一下包
                    Exif.getData(file, function() {
                    Orientation = Exif.getTag(this, 'Orientation')
                    })
                    // 看支持不支持FileReader
                    if (!file || !window.FileReader) return
                    if (/^image/.test(file.type)) {
                    // 创建一个reader
                    let reader = new FileReader()
                    // 将图片2将转成 base64 格式
                    reader.readAsDataURL(file)
                    // 读取成功后的回调
                    reader.onloadend = function() {
                        let result = this.result
                        let img = new Image()
                        img.src = result
                        //判断图片是否大于500K,是就直接上传，反之压缩图片
                        if (this.result.length <= 500 * 1024) {
                        // 上传图片
                        self.postImg(this.result);
                        } else {
                        img.onload = function() {
                            let data = self.compress(img, Orientation)
                            // 上传图片
                            self.postImg(data);
                        }
                        }
                    }
                    }
                },
                // 压缩图片
                compress(img, Orientation) {
                    let canvas = document.createElement('canvas')
                    let ctx = canvas.getContext('2d')
                    //瓦片canvas
                    let tCanvas = document.createElement('canvas')
                    let tctx = tCanvas.getContext('2d')
                    // let initSize = img.src.length;
                    let width = img.width
                    let height = img.height
                    //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
                    let ratio
                    if ((ratio = (width * height) / 4000000) > 1) {
                    // console.log("大于400万像素");
                    ratio = Math.sqrt(ratio)
                    width /= ratio
                    height /= ratio
                    } else {
                    ratio = 1
                    }
                    canvas.width = width
                    canvas.height = height
                    //    铺底色
                    ctx.fillStyle = '#fff'
                    ctx.fillRect(0, 0, canvas.width, canvas.height)
                    //如果图片像素大于100万则使用瓦片绘制
                    let count
                    if ((count = (width * height) / 1000000) > 1) {
                    // console.log("超过100W像素");
                    count = ~~(Math.sqrt(count) + 1) //计算要分成多少块瓦片
                    //      计算每块瓦片的宽和高
                    let nw = ~~(width / count)
                    let nh = ~~(height / count)
                    tCanvas.width = nw
                    tCanvas.height = nh
                    for (let i = 0; i < count; i++) {
                        for (let j = 0; j < count; j++) {
                        tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
                        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
                        }
                    }
                    } else {
                    ctx.drawImage(img, 0, 0, width, height)
                    }
                    //修复ios上传图片的时候 被旋转的问题
                    if (Orientation != '' && Orientation != 1) {
                    switch (Orientation) {
                        case 6: //需要顺时针（向左）90度旋转
                        this.rotateImg(img, 'left', canvas)
                        break
                        case 8: //需要逆时针（向右）90度旋转
                        this.rotateImg(img, 'right', canvas)
                        break
                        case 3: //需要180度旋转
                        this.rotateImg(img, 'right', canvas) //转两次
                        this.rotateImg(img, 'right', canvas)
                        break
                    }
                    }
                    //进行最小压缩
                    let ndata = canvas.toDataURL('image/jpeg', 0.2)
                    tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
                    console.log(ndata)
                    return ndata
                },
                // 旋转图片
                rotateImg(img, direction, canvas) {
                    //最小与最大旋转方向，图片旋转4次后回到原方向
                    const min_step = 0
                    const max_step = 3
                    if (img == null) return
                    //img的高度和宽度不能在img元素隐藏后获取，否则会出错
                    let height = img.height
                    let width = img.width
                    let step = 2
                    if (step == null) {
                    step = min_step
                    }
                    if (direction == 'right') {
                    step++
                    //旋转到原位置，即超过最大值
                    step > max_step && (step = min_step)
                    } else {
                    step--
                    step < min_step && (step = max_step)
                    }
                    //旋转角度以弧度值为参数
                    let degree = (step * 90 * Math.PI) / 180
                    let ctx = canvas.getContext('2d')
                    switch (step) {
                    case 0:
                        canvas.width = width
                        canvas.height = height
                        ctx.drawImage(img, 0, 0)
                        break
                    case 1:
                        canvas.width = height
                        canvas.height = width
                        ctx.rotate(degree)
                        ctx.drawImage(img, 0, -height)
                        break
                    case 2:
                        canvas.width = width
                        canvas.height = height
                        ctx.rotate(degree)
                        ctx.drawImage(img, -width, -height)
                        break
                    case 3:
                        canvas.width = height
                        canvas.height = width
                        ctx.rotate(degree)
                        ctx.drawImage(img, -width, 0)
                        break
                    }
                },
                //将base64转换为文件
                dataURLtoFile(dataurl) {
                    var arr = dataurl.split(','),
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n)
                    while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                    }
                    return new File([u8arr], this.files.name, {
                    type: this.files.type
                    })
                },
                // 提交图片到后端
                postImg(base64) {
                    let file = this.dataURLtoFile(base64)
                    let formData = new FormData()
                    formData.append('files', file);
                    formData.append('subPath','powerImg');
                    formData.append('resourceHandler','/powerImg/');
                    formData.append('basePath','powerImg');

                    // let name = this.$route.query.type+this.isnum;
                    uploads(formData).then((res)=>{
                        sessionStorage.setItem(name,res.data.data[0].url)
                        if(res.data.success){
                            this.$toast.success(res.data.msg);
                             this.fileList[0]=  res.data.data[0].url;
                            
                        }else{
                            this.$toast.fail('上传失败');
                        }
                    }) 
                }
     

    }
   
}

</script>
<style>
.titleDelectDiv{
    height: 70px;
    position: relative;
    background: #ECF6F6;
}
.delect{
    width: 83px;
    line-height: 40px;
    border: 1px solid #01A4A4;
    border-radius: 8px;
    text-align: center;

    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    position: absolute;
    top: 15px;
    right: 24px;
}
.title{
    
    line-height: 70px;
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    margin: 0px 47px;
}
.uploaderDiv{
    background: #ffffff;
    width: 100%;
}
.smalltitleDiv{
    display: flex;
    align-items: center;
    width: 88%;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: solid 1px #DADADA;
    height: 84px;
}
.smallTitle{
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.selectTime{
  text-align: right;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #A6A6A6;
    width: 30%;
}
.touploader{
    margin: 30px 6%;
}
.touploader img{
    width: 141px;
}
.smallLineDiv input{
    text-align: right;
}
.smallLineDiv p{
  line-height: 82px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
}
.smallLineDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    margin: 0 auto;
    border-bottom: 1px solid #DADADA;

}
</style>
 <style lang="less" scoped>
 .van-popup--center{
  width: 90%;
}
.van-cell{
  line-height:80px;
  padding: 0;
  
}


:deep(.van-field__control) {
    text-align: right;
}

</style> 
